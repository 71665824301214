import { defaultPlugins, useClient } from 'villus';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook('vue:setup', () => {
    const { defineAliases } = useBottomSheet();
    const cart = useCookie('cart');
    const route = useRoute();
    const isSecured = useCookie('is-secured');

    const locale = route.path.split('/')?.[1] || 'en';
    const {
      public: { apiUrl },
    } = useRuntimeConfig();
    useClient({
      url: apiUrl,

      use: [
        sourceMetaDataPlugin(),
        varnishPlugin(),
        localePlugin(locale, mapStoreLocale as any),
        readableErrorMessagePlugin(),
        ...defaultPlugins(),
      ],
    });
    clearCart();
    useAuth();
    useStoreConfig();
    useSetupCart({
      cart: cart.value,
      'is-secured': isSecured.value,
    });
    useTrackingHandlers();

    defineAliases({
      sideMenu: defineAsyncComponent(() => import('../components/SideMenu.vue')),
      acCalculator: defineAsyncComponent(() => import('../components/Modal/AcCalculator.vue')),
      commercialModal: defineAsyncComponent(() => import('../components/Modal/CommercialModal.vue')),
    });
  });
});

export function mapStoreLocale(locale: string) {
  switch (locale) {
    case 'en':
      return 'default';
    case 'ar':
      return 'ar_EG';
    default:
      return 'default';
  }
}
