/**
 * Essentials plugins used to inject some css variables and directives
 */
export default defineNuxtPlugin(() => {
  return {
    provide: {
      scrollWidth: window.innerWidth - document.documentElement.clientWidth + 'px',
    },
  };
});
