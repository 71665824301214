import { definePlugin } from 'villus';

export function readableErrorMessagePlugin() {
  // remove [GraphQL] prefix from error message
  return definePlugin(({ afterQuery }) => {
    afterQuery(result => {
      if (result.error) {
        result.error.message = result.error.message.replace('[GraphQL] ', '');
      }
    });
  });
}
