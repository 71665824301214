export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('purify', {
    async mounted(el, { value }) {
      const DOMPurify = (await import('isomorphic-dompurify')).default;
      el.innerHTML = DOMPurify.sanitize(value);
    },
    async updated(el, { value }) {
      const DOMPurify = (await import('isomorphic-dompurify')).default;
      el.innerHTML = DOMPurify.sanitize(value);
    },
  });
});
